module.exports = [{
      plugin: require('../../node_modules/gatsby-plugin-react-helmet-canonical-urls/gatsby-browser.js'),
      options: {"plugins":[],"siteUrl":"https://katahdin-lawn.lobstermarketing.com","noQueryString":true},
    },{
      plugin: require('../../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Demo Lawn Katahdin","short_name":"Demo Lawn Katahdin","start_url":"/","icon":"src/images/lobster512.png","include_favicon":false,"legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","cacheDigest":"c0c0319c0184e810c2250634be32bb82"},
    },{
      plugin: require('../../node_modules/gatsby-plugin-google-tagmanager-timeout/gatsby-browser.js'),
      options: {"plugins":[],"id":"GTM-PHR5M4C","includeInDevelopment":false,"timeout":5000,"defaultDataLayer":{"type":"object","value":{"platform":"gatsby"}},"routeChangeEventName":"page-change"},
    },{
      plugin: require('../../node_modules/gatsby-plugin-breadcrumb/gatsby-browser.js'),
      options: {"plugins":[],"useAutoGen":true,"autoGenHomeLabel":"Home","exclude":["/dev-404-page/","/404/","/404.html"],"crumbLabelUpdates":[{"pathname":"/blog","crumbLabel":"Blog"},{"pathname":"/photo-gallery","crumbLabel":"Photo Gallery"},{"pathname":"/where-we-service","crumbLabel":"Where We Service"}]},
    },{
      plugin: require('../../node_modules/gatsby-plugin-anchor-links/gatsby-browser.js'),
      options: {"plugins":[],"offset":-70},
    },{
      plugin: require('../../gatsby-theme-lawn-katahdin/gatsby-browser.js'),
      options: {"plugins":[],"projectId":"50d11fcb-4a55-00f7-41df-391226ddc25e","authorizationKey":"ew0KICAiYWxnIjogIkhTMjU2IiwNCiAgInR5cCI6ICJKV1QiDQp9.ew0KICAianRpIjogImFkNjhiZTRmYjY3YzRjNmE4NzNjMjNhMGJkZjRlYWNiIiwNCiAgImlhdCI6ICIxNjM1MzQxNjI1IiwNCiAgImV4cCI6ICIxOTgwOTQxNjI1IiwNCiAgInByb2plY3RfaWQiOiAiNTBkMTFmY2I0YTU1MDBmNzQxZGYzOTEyMjZkZGMyNWUiLA0KICAidmVyIjogIjEuMC4wIiwNCiAgImF1ZCI6ICJwcmV2aWV3LmRlbGl2ZXIua2VudGljb2Nsb3VkLmNvbSINCn0.CO82XOPPFiKibpQDbGZfVOUoRzP9KPrdcrAgSU_Hw0c","usePreviewUrl":"false","whereWeServicePathName":"where-we-service","whereWeServiceBreadcrumb":"Where We Service","wWSActive":"0","siteUrl":"https://katahdin-lawn.lobstermarketing.com","title":"Demo Lawn Katahdin","description":"FieldRoutes","domainVerification":"meta-tag","gtmId":"GTM-PHR5M4C","compassID":"8178cfb10750a2a33c491689ef9ff80ab3043c2dea7a983121c513d31d0a3aa9","recaptchaSiteKey":"6LeartohAAAAAGS3t15S7c8JmRqKPFWd3065Jypm","recaptchaSecretKey":"6LeartohAAAAANFLzYuwUKNlT1AZ2a-KybcovPey","photoGalleryPathName":"photo-gallery","photoGalleryBreadcrumb":"Photo Gallery","photoGalleryActive":"0"},
    },{
      plugin: require('../../node_modules/gatsby-plugin-gatsby-cloud/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
